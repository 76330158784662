<template>
  <b-overlay :show="loading">
    <section>
      <div class="d-none d-md-block">
        <div class="mt-md-4">
          <list-table
              :records="loans"
              :columns="tableColumns"
              :total-records="totalRecords"
              :show-search="false"
              :show-filter-toggle-button="false"
              :current-page-number.sync="currentPage"
              @row-clicked="onRowClicked"
          >


            <!-- Column: Purpose -->
            <template #cell(purpose)="data">
              <span>{{ capitalizeFirstWord(data.value) }}</span>
            </template>


            <!-- Column: Reason -->
            <template #cell(reason_for_loan)="data">
              <span>{{ data.value || '-' }}</span>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge pill :variant="`light-${resolveStatusVariant(data.item.status)}`" class="text-capitalize my-1">
                {{
                  ['pending-product-delivery', 'flagged', 'admin-approve'].includes(data.item.status) ? 'Pending' : capitalizeFirstWord(data.item.status)
                }}
              </b-badge>
            </template>

            <template #cell(action)="data">
              <b-button
                  variant="primary"
                  :to="{ name: 'client-statements-single',
                         params: { loan_id: data.item._id }
                        }"
                  size="sm"
              >
                View Statements
              </b-button>
            </template>

          </list-table>
        </div>
      </div>

      <div class="d-md-none">
        <div class="d-flex justify-content-end align-items-center">
          <b-button
              variant="primary"
              :to="{ name: 'client-select-loan-product' }"
              :disabled="!isLoansModuleEnabled"
          >
            <span class="text-nowrap">Get New Loan</span>
          </b-button>

        </div>

        <div>
          <div v-for="(loan, i) in loans" :key="i">
            <div class="border-bottom">
              <div>
                <div class="d-flex align-items-center justify-content-between mb-50">
                  <div></div>
                  <div>
                    <b-badge
                        pill
                        :variant="`light-${resolveStatusVariant(getValueFromSource(loan, 'status'))}`"
                        class="text-capitalize my-1"
                    >
                      {{ getUserStatusText(getValueFromSource(loan, 'status')) }}

                    </b-badge>
                  </div>
                </div>

                <div class="mb-1">
                  <small>Loan Amount</small>
                  <h1> <small class="text-black">Ghc </small> {{ formatMoney(getValueFromSource(loan, 'amount') ) }}</h1>
                </div>


                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <div class="">
                      <small class="mr-1">Reason </small>
                      <h5 class="p-0 m-0">
                        {{ getValueFromSource(loan, 'reason_for_loan') }}
                      </h5>
                    </div>
                  </div>
                  <div class="ml-2">
                    <router-link :to="{ name: 'client-statements-single', params: { loan_id: getValueFromSource(loan, '_id') } }">
                      <b-button variant="primary">
                        View Statement
                      </b-button>
                    </router-link>
                  </div>
                </div>


                <div class="mt-2">
                  <p class="border-left pl-1">
                    {{ formatDate(getValueFromSource(loan, 'created'), 'dd MMMM yyyy') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </b-overlay>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import ListTable from "@core/components/ListTable/ListTable.vue";
import LoanListFilters from "@/pages/client/loans/list/LoanListFilters.vue";

import { debounce, get } from "lodash";
import { formatDate, formatMoney } from "@core/utils/utils";
import { BBadge, BButton, BDropdown, BDropdownGroup, BDropdownItem, BOverlay } from "bootstrap-vue";

const watchHandler = {
  handler() {
    this.debounceFetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler() {
    this.currentPage = 1;
    this.debounceFetchAllData()
  },
  immediate: false
}

export default {
  name: "ListLoans",
  components: {
    LoanListFilters,
    ListTable,
    BBadge,
    BButton,
    BOverlay,
    BDropdown,
    BDropdownGroup,
    BDropdownItem
  },
  data() {
    return {
      loans: [],
      loading: false,
      statusFilter: "",
      tableColumns: [
        {
          key: 'created',
          sortable: false,
          formatter: val => `${formatDate(val, 'dd MMMM yyyy')}`,
          label: 'Date',
        },
        {
        key: 'amount',
        sortable: false,
        label: 'Amount',
        formatter: val => `GH¢ ${formatMoney(val)}`
      },
        {
          key: 'status',
          sortable: false
        },
        {
          key: 'purpose',
          sortable: false
        },
        {
          key: 'reason_for_loan',
          sortable: false,
          label: "Reason For Loan"
        },
        {
          key: 'action',
          label: ''
        }
      ],

      debounceFetchAllData: null,
    }
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value);
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get() {
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    perPage: watchHandlerRequiresPageReset,
    statusFilter: watchHandlerRequiresPageReset,
    user_id: watchHandlerRequiresPageReset,
    currentPage: watchHandler,
    userId: {
      handler(user_id) {
        if (user_id) {
          this.user_id = user_id
        }
      },
      immediate: true
    },

  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchLoanRequests, 500);

    const {
      status,
      from,
      to,
      user_id,
      page = 1,
      search = "",
      limit = this.perPage
    } = this.$route.query;

    this.dateEnd = to;
    this.perPage = +limit;
    this.dateStart = from;
    this.user_id = user_id;
    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;

    this.$socket.client.on("REFRESH_PAGE_DATA", (payload) => {
      if (payload.name && payload.name === this.$route.name) {
        this.fetchLoanRequests()
      }
    });
  },
  methods: {
    async fetchLoanRequests() {
      try {
        const query = {
          limit: this.perPage,
          page: this.currentPage,
        };

        this.$router.push({
          query
        }).catch(() => {});

        const request = await useJwt.clientService.fetchProcessedLoanRecords(query);
        const {
          data,
          pagination
        } = request.data;
        this.totalRecords = pagination.totalRecords
        this.loans = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    onRowClicked(rowItem) {
      this.$router.push({
        name: 'client-statements-single',
        params: {
          loan_id: rowItem._id
        }
      })
          .catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
